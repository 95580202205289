import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserClientInterface } from "../interfaces/user-client.interface";
import { UserClient } from "../domains/user-client/user-client";
import { Observable, catchError, lastValueFrom, map, of, EMPTY as GO_TO_ON_COMPLETE } from "rxjs";
import { ToastComponent } from "../components/toaster/toast/toast.component";
import { ResumedUserClient, UserClientPage } from "../domains/user-client/resumed-user-client";
import { LocalStorageKeys } from '../enums/storage-keys';


@Injectable({
  providedIn: 'root'
})
export class UserClientService implements UserClientInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async getAll(): Promise<UserClient[]> {
    try {
      const userClients: Array<UserClient> = await lastValueFrom(this.http.get<Array<UserClient>>(this.urlBase + `/users-clients`));
      if (userClients) {
        return userClients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/users-clients/${id}`, { observe: 'response' }));
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw error;
    }
    return false;
  }

  async save(userClient: UserClient): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/users-clients`, userClient, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async update(userClient: UserClient, image?: File): Promise<boolean> {
    const formData = new FormData();
    formData.append(
      'userClient',
      new Blob([JSON.stringify(userClient)], { type: 'application/json' })
    );

    if (image) {
      formData.append('image', image, image.name);
    }

    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.put(this.urlBase + `/users-clients`, formData, { observe: 'response' }));

      if (success.status == 204) {
        return true;
      }

    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async getByClients(filter?: Optional): Promise<Array<UserClient>> {
    try {
      const usersClients: Array<UserClient> = await lastValueFrom(this.http.get<Array<UserClient>>(this.urlBase + `/users-clients/clients?id=` + filter + `&isActive=true`));

      if (usersClients) {
        return usersClients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async findById(id: number): Promise<UserClient> {
    try {
      const userClients = await lastValueFrom(this.http.get<UserClient>(this.urlBase + `/users-clients/${id}`));
      return userClients;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async existsByCpf(cpf: string): Promise<Boolean> {
    try {
      return await lastValueFrom(this.http.get<Boolean>(this.urlBase + `/users-clients/exists-by/cpf/${cpf}`));
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async existsByEmail(email: string): Promise<Boolean> {
    try {
      return await lastValueFrom(this.http.get<Boolean>(this.urlBase + `/users-clients/exists-by/email/${email}`));
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getResumedUserClient(userId: number): Promise<ResumedUserClient> {
    try {
      const userClient = await lastValueFrom(this.http.get<ResumedUserClient>(this.urlBase + `/users-clients/user/${userId}`));
      return userClient;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getUserClientPageByClientsIds(clientsIds: number[]): Promise<UserClientPage> {
    try {
      const userClientPage: any = await lastValueFrom(
        this.http.get<ResumedUserClient[]>(this.urlBase + `/users-clients/page/clients`, {
          params: {
            clientsIds: clientsIds
          }
        })
      );

      if (userClientPage) {
        return userClientPage;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getFilteredUserClientPageByClientsIds({
    clientsIds,
    filter,
    pageSize,
    page,
  }: any): Promise<UserClientPage> {
    try {
      const userClientPage: any = await lastValueFrom(
        this.http.get<ResumedUserClient[]>(this.urlBase + `/users-clients/page/clients`, {
          params: {
            clientsIds: clientsIds,
            filter: filter,
            pageSize: pageSize,
            page: page
          },
        })
      );

      if (userClientPage) {
        return userClientPage;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async changeSituation(id: number, situation: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(this.http.put(this.urlBase + `/users-clients/change-situation/${id}`, params));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async changeSituationByGroupIds(ids: number[], situation: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(this.http.put(this.urlBase + `/users-clients/change-situation?ids=${ids.join(',')}`, params));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async deleteUserClientByGroupId(ids: number[]): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/users-clients?ids=${ids.join(',')}`, {
          observe: 'response',
        })
      );

      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }

  async resetPasswords(ids: number[]): Promise<boolean> {
    try {
      await lastValueFrom(this.http.post(this.urlBase + `/users-clients/reset-password?userIds=${ids.join(',')}`, null));
      return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getResumedUserClientByUserIdAndClientId(id: number): Promise<ResumedUserClient> {
    const cachedClientId = localStorage.getItem(LocalStorageKeys.WRH_CACHE_CLIENT_ID);
    try {
      const userClient = await lastValueFrom(this.http.get<ResumedUserClient>(this.urlBase + `/users-clients/user-client/${id}/${cachedClientId}`));
      return userClient;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getByClientsCentralized(isActive?: boolean): Promise<Array<UserClient>> {
    try {
      const params = {};

      if (isActive) Object.assign(params, { isActive });

      const usersClients: Array<UserClient> = await lastValueFrom(this.http.get<Array<UserClient>>(this.urlBase + `/users-clients/clients/centralized`, { params }));

      if (usersClients) {
        return usersClients;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }
}
