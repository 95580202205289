<div class="container-fluid" [formGroup]="form" *ngIf="
    !(
      (showSubscriptionModal ||
        showSubscriptionsListModal) &&
      globalFunctions.isMobile()
    )
  ">
  <div class="margin-15">
    <div class="d-flex align-items-center justify-content-between">
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="!id">
        Novo Colaborador
      </label>
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="id && canEdit">
        Editar Colaborador
      </label>
      <label class="titles title-2 semibold extended-secondary-darker" *ngIf="id && !canEdit">
        Visualizar Colaborador
      </label>
    </div>

    <div class="row align-items-center" style="margin-top: 16px" formGroupName="personalData">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-label class="paragraph paragraph-2 mat-label">Cliente</mat-label>
          <input matInput formControlName="clientName" />
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="margin-top: 16px; margin-bottom: 15px">
      <div class="col-2">
        <label class="subtitle subtitle-2 semibold extended-secondary-darker">Dados Pessoais</label>
      </div>
      <mat-chip *ngIf="form.get('isFired')?.value" class="chip" style="height: fit-content; margin-top: 5px" disabled>
        <span class="bold">Demitido</span>
      </mat-chip>
    </div>

    <div class="form-div" formGroupName="personalData">
      <div class="row">

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Origem</mat-label>
            <mat-select [disableOptionCentering]="true" panelClass="mat-select-panel-below" formControlName="origin">
              <mat-option *ngFor="let origin of origins" [value]="origin.value">
                {{ origin.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Matrícula</mat-label>
            <input matInput formControlName="employeeEnrollment" maxlength="40" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">CPF</mat-label>
            <input matInput [mask]="masks.cpfMask" formControlName="cpf" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Data de nasc.</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthDate" appMaskDate maxlength="10" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
          <div class="paragraph paragraph-2 switchPosition">
            <label class="pass-13 secondary-neutral">Situação</label>
          </div>
          <div class="d-flex align-items-center">
            <input type="checkbox" name="togle" (click)="form.disabled ? $event.preventDefault() : ''" [style]="
                form.disabled
                  ? 'cursor: not-allowed; background-color: #B0E3E1;'
                  : ''
              " id="togle" class="switchButton" formControlName="situation" />
            <label for="togle" class="label-st">
              {{
              form.get("personalData")?.get("situation")?.value
              ? "Ativo"
              : "Inativo"
              }}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Nome</mat-label>
            <input matInput formControlName="name" maxlength="40" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Sobrenome</mat-label>
            <input matInput formControlName="lastName" maxlength="40" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Como quer ser chamado?</mat-label>
            <input matInput formControlName="nickName" maxlength="100" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Sexo</mat-label>
            <mat-select [disableOptionCentering]="true" panelClass="mat-select-panel-below" formControlName="sex">
              <mat-option *ngFor="let sex of sexes" [value]="sex.value">
                {{ sex.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Raça/Cor</mat-label>
            <mat-select [disableOptionCentering]="true" panelClass="mat-select-panel-below" formControlName="ethnicity">
              <mat-option *ngFor="let ethnicity of ethnicities" [value]="ethnicity.value">
                {{ ethnicity.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Estado Civil</mat-label>
            <mat-select [disableOptionCentering]="true" panelClass="mat-select-panel-below"
              formControlName="maritalStatus">
              <mat-option *ngFor="let status of maritalStatuses" [value]="status.value">
                {{ status.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Cargo</mat-label>
            <input matInput formControlName="role" maxlength="60" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Celular</mat-label>
            <input matInput formControlName="primaryPhone" [mask]="masks.phoneMask" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Celular</mat-label>
            <input matInput [mask]="masks.phoneMask" formControlName="secondaryPhone" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">E-mail Corporativo</mat-label>
            <input matInput formControlName="corporateEmail" maxlength="40" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">E-mail Pessoal</mat-label>
            <input matInput formControlName="personalEmail" maxlength="40" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Qual e-mail prefere usar no APP Comunify?</mat-label>
            <mat-select [disableOptionCentering]="true" panelClass="mat-select-panel-below"
              formControlName="selectedEmail">
              <mat-option *ngFor="let type of preferredEmailTypes" [value]="type.value">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Escolaridade</mat-label>
            <mat-select [disableOptionCentering]="true" panelClass="mat-select-panel-below" formControlName="education">
              <mat-option *ngFor="let education of educations" [value]="education.value" [matTooltip]="education.label">
                {{ education.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Nacionalidade</mat-label>
            <input matInput formControlName="nationality" maxlength="40" />
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Naturalidade</mat-label>
            <input matInput formControlName="naturalness" maxlength="40" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 16px; margin-bottom: 15px">
      <div class="col-12">
        <label class="subtitle subtitle-2 semibold extended-secondary-darker">Endereço Residencial</label>
      </div>
    </div>

    <div class="form-div" formGroupName="residentialAddress">
      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" [class]="
              form.get('residentialAddress')?.get('cep')?.dirty &&
              form.get('residentialAddress')?.get('cep')?.invalid
                ? 'mat-form-field-invalid'
                : 'mat-input'
            ">
            <mat-label class="paragraph paragraph-2 mat-label">CEP</mat-label>
            <input matInput formControlName="cep" [mask]="masks.cepMask" />
            <mat-hint *ngIf="
                form.get('residentialAddress')?.get('cep')?.dirty &&
                form.get('residentialAddress')?.get('cep')?.invalid
              " class="pass-13 mat-label-error">Número Inválido.</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Logradouro</mat-label>
            <input matInput formControlName="street" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Número</mat-label>
            <input matInput formControlName="number" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Complemento</mat-label>
            <input matInput formControlName="complement" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Bairro</mat-label>
            <input matInput formControlName="district" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Cidade</mat-label>
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Estado</mat-label>
            <mat-select formControlName="state" [disableOptionCentering]="true" panelClass="mat-select-panel-below"
              [disabled]="!beneficiaryEnable" (openedChange)="searchStateFormControl.setValue('')">
              <div class="search-select">
                <mat-form-field appearance="outline" class="mat-input p-0">
                  <mat-icon matPrefix class="icon-search">search</mat-icon>
                  <mat-label>Buscar Estado</mat-label>
                  <input matInput [formControl]="searchStateFormControl" (keydown.space)="$event.stopPropagation()" />
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of filteredStates" [value]="state">
                {{ state }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 16px; margin-bottom: 15px">
      <div class="col-sm-4 d-flex"
        [style]="globalFunctions.isMobile() ? 'flex-direction: column; align-items: flex-start': 'align-items: center'">
        <label class="subtitle subtitle-2 semibold extended-secondary-darker">Endereço de Entrega</label>
        <div class="col-sm-2" [style]="globalFunctions.isMobile() ? 'margin: 0px 0px 20px -14px;' : ''">
          <mat-checkbox id="checkbox1" style="margin-top: -6px" [disabled]="form.disabled" [checked]="
              form.get('deliverAddress')?.get('isSameAddressAsResidentialAddress')
                ?.value
            " (change)="setDeliveryAddress(true)">
            <label for="checkbox1" class="paragraph paragraph-2 label-checkbox click"
              (click)="setDeliveryAddress(true)">
              Mesmo endereço residencial
            </label>
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="form-div" formGroupName="deliverAddress">
      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" [class]="
              form.get('deliverAddress')?.get('cep')?.dirty &&
              form.get('deliverAddress')?.get('cep')?.invalid
                ? 'mat-form-field-invalid'
                : 'mat-input'
            ">
            <mat-label class="paragraph paragraph-2 mat-label">CEP</mat-label>
            <input matInput placeholder="" formControlName="cep" [mask]="masks.cepMask" />
            <mat-hint *ngIf="
                form.get('deliverAddress')?.get('cep')?.dirty &&
                form.get('deliverAddress')?.get('cep')?.invalid
              " class="pass-13 mat-label-error">Número Inválido.</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Logradouro</mat-label>
            <input matInput placeholder="" formControlName="street" />
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Número</mat-label>
            <input matInput placeholder="" formControlName="number" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Complemento</mat-label>
            <input matInput placeholder="" formControlName="complement" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Bairro</mat-label>
            <input matInput placeholder="" formControlName="district" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Cidade</mat-label>
            <input matInput placeholder="" formControlName="city" />
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <mat-form-field appearance="outline" class="mat-input">
            <mat-label class="paragraph paragraph-2 mat-label">Estado</mat-label>
            <mat-select formControlName="state" [disableOptionCentering]="true" panelClass="mat-select-panel-below"
              (openedChange)="searchStateFormControl.setValue('')">
              <div class="search-select">
                <mat-form-field appearance="outline" class="mat-input p-0">
                  <mat-icon matPrefix class="icon-search">search</mat-icon>
                  <mat-label>Buscar Estado</mat-label>
                  <input matInput [formControl]="searchStateFormControl" (keydown.space)="$event.stopPropagation()" />
                </mat-form-field>
              </div>
              <mat-option *ngFor="let state of filteredStates" [value]="state">
                {{ state }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="form-div">
      <div class="col-sm-3 mb-4">
        <div class="anexo-area" style="margin-left: -10px;">
          <div class="margin-15">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <label class="subtitle subtitle-2 semibold extended-secondary-darker ml-2">Foto</label>
                <div class="ml-2">
                  <app-custom-tooltip [text]="'Formatos: JPEG e PNG.\nTamanho máximo: 15MB.'"
                    [tooltipStyleClass]="'info-img'" [tooltipPosition]="'right'" [styleTooltip]="'margin-top: -3rem;'">
                  </app-custom-tooltip>
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-8">
                <div class="container-img" (dragover)="!canEdit ? $event.preventDefault() : onDragOver($event)"
                  (drop)="!canEdit ? $event.preventDefault() : onDrop($event)">
                  <div class="anexo-area" style="min-width: 100%; margin-bottom: -20px; padding-bottom: 20px;">
                    <div class="col-12">
                      <div class="row justify-content-end" *ngIf="selectedImage && canEdit">
                        <mat-icon class="icon-file click" (click)="removeImg(imgInput)">close</mat-icon>
                      </div>
                    </div>
                    <div class="img-anexo">
                      <div class="d-flex flex-column" *ngIf="!selectedImage">
                        <img src="../../../../assets/img/images.png" />
                        <p class="subtitle-1 text-center">Arraste a imagem aqui ou</p>
                      </div>
                      <img *ngIf="selectedImage" (click)="downloadImage(selectedImage, getFileName())"
                        [src]="sanitizeImageUrl(selectedImage)" alt="Imagem selecionada" class="img-responsive" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button mat-button [disabled]="!canEdit"
                        class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                        (click)="!canEdit ? null : imgInput.click()">Selecione o arquivo</button>&nbsp;
                      <input type="file" multiple #imgInput style="display: none" (change)="onFileChange($event)"
                        accept="image/png, image/jpeg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 16px; margin-bottom: 15px">
      <div class="col-12">
        <label class="subtitle subtitle-2 semibold extended-secondary-darker">Redes Sociais</label>
      </div>
    </div>

    <div class="form-div" formGroupName="socialNetworks">
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <i matPrefix class="pi pi-instagram icon-social" style="font-size: 1.5rem"></i>
            <mat-label class="paragraph paragraph-2 mat-label">Instagram</mat-label>
            <input matInput formControlName="instagram" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <i matPrefix class="pi pi-facebook icon-social" style="font-size: 1.5rem"></i>
            <mat-label class="paragraph paragraph-2 mat-label">Facebook</mat-label>
            <input matInput formControlName="facebook" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field appearance="outline" class="mat-input">
            <i matPrefix class="pi pi-linkedin icon-social" style="font-size: 1.5rem"></i>
            <mat-label class="paragraph paragraph-2 mat-label">LinkedIn</mat-label>
            <input matInput formControlName="linkedin" />
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="container-fluid" [formGroup]="form" *ngIf="
    !(
      (showSubscriptionModal ||
        showSubscriptionsListModal) &&
      globalFunctions.isMobile()
    )
  ">
  <div class="margin-15">
    <div class="row" *ngIf="!globalFunctions.isMobile()">
      <div class="col-sm-2">
        <button mat-button [disabled]="!canResetPass"
          class="btn btn-tertiary subtitle subtitle-1 semibold primary-color" (click)="resetPassWord()" *ngIf="id">
          <mat-icon class="icon-btn">key</mat-icon>Resetar senha
        </button>
      </div>
      <div class="col-sm-2">
        <button mat-button [disabled]="!canDelete" class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
          (click)="confirmDelete()" *ngIf="id">
          <mat-icon class="icon-btn">delete</mat-icon>Excluir Colaborador
        </button>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-2">
        <button mat-button (click)="confirmCancel()"
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color">
          Cancelar</button>&nbsp;
      </div>
      <div class="col-sm-2">
        <button mat-button (click)="saveOrUpdate()" [disabled]="isNotValidForm()"
          class="btn btn-primary subtitle subtitle-1 semibold white">
          Salvar</button>&nbsp;
      </div>
    </div>

    <div class="row my-5" formGroupName="personalData" *ngIf="globalFunctions.isMobile()">
      <div class="col-6">
        <div class="paragraph paragraph-2 switchPosition">
          <label class="pass-13 secondary-neutral">Situação</label>
        </div>
        <div class="d-flex align-items-center">
          <input type="checkbox" name="togle" (click)="!canChangeSituation ? $event.preventDefault() : ''" id="togle"
            class="switchButton" formControlName="situation" />
          <label for="togle" class="label-st">
            {{
            form.get("personalData")?.get("situation")?.value
            ? "Ativo"
            : "Inativo"
            }}
          </label>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end" *ngIf="id">
        <button mat-mini-fab (click)="confirmDelete()" [disabled]="!canDelete"
          class="btn btn-tertiary subtitle subtitle-1 semibold primary-color bg-white">
          <mat-icon class="icon-btn ml-0 material-icons-outlined">delete</mat-icon>
        </button>
      </div>
    </div>

    <div class="row justify-content-end" *ngIf="globalFunctions.isMobile()">
      <div class="col-sm-2">
        <button mat-button (click)="saveOrUpdate()" [disabled]="isNotValidForm()"
          class="btn btn-primary subtitle subtitle-1 semibold white">
          Salvar</button>&nbsp;
      </div>
      <div class="col-sm-2">
        <button mat-button (click)="confirmCancel()"
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color">
          Cancelar</button>&nbsp;
      </div>
    </div>
  </div>
</div>

<app-confirm-modal #confirmModal></app-confirm-modal>